import translations from '../../../utils/translations'
import { getGlobalDesignPresets } from '../preset/preset-service'
import { BASE_DESIGN_GROUPS, COMPONENT_TYPES, FIELD_COMPONENT_TYPES } from '@wix/forms-common'
import {
  ROLE_NEXT_BUTTON,
  ROLE_PREVIOUS_BUTTON,
  ROLE_SUBMIT_BUTTON,
} from '../../../constants/roles'
import { baseWidgetDesignMappingValues } from '../preset/fields/constants'
import _ from 'lodash'

const enum CONTROLS_STATES {
  REGULAR = 'regular',
  HOVER = 'hover',
  FOCUS = 'focus',
  ERROR = 'error',
}

const getStyleParamOverrides = () => ({
  [FIELD_COMPONENT_TYPES.CHECKBOX_GROUP]: {
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]]: ['bgc'],
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.LABEL_TEXT_COLOR]]: ['txt2', 'txt'],
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.LABEL_TEXT_FONT]]: ['fnt2'],
  },
  [FIELD_COMPONENT_TYPES.SINGLE_CHECKBOX]: {
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]]: ['bgc'],
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.LABEL_TEXT_COLOR]]: ['txt'],
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.INPUT_BORDER_SIZES]]: [],
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.INPUT_BORDER_SIZES_HOVER]]: [],
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.INPUT_BORDER_SIZES_FOCUS]]: [],
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.INPUT_BORDER_SIZES_ERROR]]: [],
  },
  [FIELD_COMPONENT_TYPES.DATE_PICKER]: {
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]]: [
      'txt-placeholder',
      'icn-color',
      'txt-day',
      'txt-header',
    ],
  },
  [FIELD_COMPONENT_TYPES.RADIO_GROUP]: {
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]]: ['bg2'],
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.LABEL_TEXT_COLOR]]: ['txt2', 'txt'],
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.LABEL_TEXT_FONT]]: ['fnt2'],
  },
  [FIELD_COMPONENT_TYPES.SIGNATURE_INPUT]: {
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.MAIN_TEXT_COLOR]]: ['signatureColor'],
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]]: ['padLineColor'],
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.LABEL_TEXT_COLOR]]: [
      'titleColor',
      'titleAsteriskColor',
    ],
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.LABEL_TEXT_FONT]]: ['titleFont'],
  },
  [FIELD_COMPONENT_TYPES.RATING]: {
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.LABEL_TEXT_COLOR]]: [
      'labelFontColor',
      'filledShapeColor',
      'shapeBorderColor',
    ],
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.LABEL_TEXT_FONT]]: ['labelFont'],
  },
  [FIELD_COMPONENT_TYPES.FILE_UPLOADER]: {
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]]: [
      'btn_txt',
      'x_file_txt',
    ],
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.MAIN_TEXT_FONT]]: ['btn_fnt'],
  },
  [FIELD_COMPONENT_TYPES.TIME_PICKER]: {
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.MAIN_TEXT_COLOR]]: ['textColor'],
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]]: [
      'placeholderTextColor',
    ],
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.MAIN_TEXT_FONT]]: ['textFont'],
  },
  [FIELD_COMPONENT_TYPES.COMBOBOX]: {
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.MAIN_TEXT_COLOR]]: [
      'txt',
      'txt_placeholder',
      'arrowColor',
    ],
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]]: [
      'txt',
      'txt_placeholder',
      'arrowColor',
    ],
  },
  [FIELD_COMPONENT_TYPES.ADDRESS_INPUT]: {
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.MAIN_TEXT_COLOR]]: [
      'dropdownHoverTextColor',
      'dropdownLocationIconColor',
      'dropdownTextColor',
      'inputTextColor',
    ],
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]]: [
      'inputPlaceholderTextColor',
    ],
    [baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.MAIN_TEXT_FONT]]: [
      'dropdownFont',
      'inputFont',
      'inputPlaceholderFont',
    ],
  },
})

const getTabs = (isMultiStep) => {
  const inputsTab = {
    label: translations.t('manifest.globalDesign.inputsCompPart'),
    sections: [
      {
        state: 'active',
        category: 'text',
        label: translations.t('manifest.globalDesign.inputSection'),
        priority: 2,
        subsections: [
          {
            label: translations.t('manifest.globalDesign.InputTextColor'),
            priority: -1,
            styleParam: baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.MAIN_TEXT_COLOR],
          },
          {
            label: translations.t('manifest.globalDesign.placeholderTextColor'),
            styleParam: baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR],
            priority: -2,
          },
        ],
        roles: [],
        styleParam: baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.MAIN_TEXT_FONT],
      },
      {
        state: 'active',
        category: 'text',
        label: translations.t('manifest.globalDesign.labelSection'),
        priority: 1,
        subsections: [
          {
            label: translations.t('manifest.globalDesign.labelTextColor'),
            styleParam: baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.LABEL_TEXT_COLOR],
          },
          {
            label: translations.t('manifest.globalDesign.labelRequiredTextColor'),
            styleParam: baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.LABEL_REQUIRED_TEXT_COLOR],
          },
        ],
        roles: [],
        styleParam: baseWidgetDesignMappingValues[BASE_DESIGN_GROUPS.LABEL_TEXT_FONT],
      },
    ],
    styleParamOverrides: getStyleParamOverrides(),
    groups: {
      compTypes: [
        FIELD_COMPONENT_TYPES.TEXT_INPUT,
        FIELD_COMPONENT_TYPES.TEXT_AREA_INPUT,
        FIELD_COMPONENT_TYPES.SINGLE_CHECKBOX,
        FIELD_COMPONENT_TYPES.DATE_PICKER,
        FIELD_COMPONENT_TYPES.RADIO_GROUP,
        FIELD_COMPONENT_TYPES.SIGNATURE_INPUT,
        FIELD_COMPONENT_TYPES.RATING,
        FIELD_COMPONENT_TYPES.FILE_UPLOADER,
        FIELD_COMPONENT_TYPES.TIME_PICKER,
        FIELD_COMPONENT_TYPES.CHECKBOX_GROUP,
        FIELD_COMPONENT_TYPES.COMBOBOX,
        FIELD_COMPONENT_TYPES.ADDRESS_INPUT,
      ],
    },
  }

  const buttonTab = {
    label: translations.t('manifest.globalDesign.submitButton'),
    sections: [],
    groups: { compTypes: [COMPONENT_TYPES.SITE_BUTTON] },
  }

  const multiStepButtonsTab = {
    label: translations.t('manifest.globalDesign.multiStepButtons'),
    sections: [
      {
        state: 'active',
        category: 'fill',
        label: translations.t('manifest.globalDesign.multiStepNextSection'),
        priority: 1,
        roles: [ROLE_NEXT_BUTTON, ROLE_SUBMIT_BUTTON],
        states: [CONTROLS_STATES.REGULAR, CONTROLS_STATES.HOVER],
      },
      {
        state: 'active',
        category: 'fill',
        label: translations.t('manifest.globalDesign.multiStepPreviousSection'),
        priority: 2,
        roles: [ROLE_PREVIOUS_BUTTON],
        states: [CONTROLS_STATES.REGULAR, CONTROLS_STATES.HOVER],
      },
    ],
    dependents: [ROLE_NEXT_BUTTON, ROLE_PREVIOUS_BUTTON],
    groups: { compTypes: [COMPONENT_TYPES.SITE_BUTTON] },
  }

  const containerTab = {
    label: translations.t('manifest.globalDesign.containerCompPart'),
    sections: [],
    groups: { compTypes: [COMPONENT_TYPES.FORM_CONTAINER, COMPONENT_TYPES.STATE_BOX] },
  }

  const tabs = [inputsTab, isMultiStep ? multiStepButtonsTab : buttonTab, containerTab]

  return tabs
}

export const getWidgetDesignManifest = (isMultiStep?: boolean) => ({
  tabs: getTabs(isMultiStep),
  presets: getGlobalDesignPresets(),
  title: translations.t('manifest.globalDesign.title'),
  presetsTitle: translations.t('manifest.globalDesign.presetsTitle'),
  customHelpId: '2e7088dd-c331-44b6-8e43-180c136ee254',
  presetHelpId: '56086989-6a7f-400e-985c-d9a42c81c697',
})
