import { ModalSkins } from '../../constants/panel-names'

export type WARNING_PANELS = 'dontShowContributorsWarning'

export interface WarningPanelDefinition {
  title: string
  panelTitle: string
  helpId?: string
  hideLearnMore?: boolean
  hideUserPreferences?: boolean
  bullets: string[]
  ok: string
  cancel: string
  height: number
}

const height = (skin: ModalSkins) => {
  switch (skin) {
    case ModalSkins.NewWorkspace:
      return 300
    case ModalSkins.Responsive:
      return 305
    default:
      return 306
  }
}
export const warningTypeToDefinition = (
  skin = ModalSkins.Classic,
): { [key in WARNING_PANELS]: WarningPanelDefinition } => {
  const useBulletsWithoutDotKeys = skin !== ModalSkins.Classic
  return {
    dontShowContributorsWarning: {
      title: 'siteContributorWarning.title',
      panelTitle: 'siteContributorWarning.panelTitle',
      helpId: '2e748e6c-e672-484a-8ec2-7a206f1a753d',
      bullets: useBulletsWithoutDotKeys
        ? [
            'siteContributorWarning.content.bulletText1',
            'siteContributorWarning.content.bulletText2',
          ]
        : ['siteContributorWarning.content.bullet1', 'siteContributorWarning.content.bullet2'],
      ok: 'siteContributorWarning.approveButtonText',
      cancel: 'siteContributorWarning.cancelButtonText',
      height: height(skin),
    },
  }
}
