import { FormsFieldPreset, CustomTypes, FormPlugin, FieldCollectionType } from '@wix/forms-common'
import { iconNames } from '../../icons/types'
import { CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { TextInput } from '../../fields-by-comp-type/definitions/text-input'
import { ServerFieldType } from '../../../../../../constants/field-types'
export class GeneralNumber extends TextInput {
  public get customFields() {
    return [CustomTypes.NUMBER]
  }

  protected get icon() {
    return iconNames.number
  }

  public get collectionFieldType() {
    return FieldCollectionType.NUMBER
  }

  protected get data(): any {
    return {
      textType: 'number',
      placeholder: this.translate(`fieldTypes.${this.fieldType}.placeholderText`),
      type: 'TextInput',
      value: '',
    }
  }

  protected get props() {
    return {
      type: 'TextInputProperties',
      required: false,
    }
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        addFieldPanelData: {
          category: CATEGORIES.number,
          subCategory: CATEGORIES.basic,
        },
      },
    }
  }

  public get fieldType(): FieldPreset {
    return FormsFieldPreset.GENERAL_NUMBER
  }

  public get serverType(): ServerFieldType {
    return ServerFieldType.NUMBER
  }

  public get base() {
    return GeneralNumber.prototype
  }
}
