import { EditorPlatformApp } from '@wix/platform-editor-sdk'
import { editorAppMetaData } from './editor-app'
import _ from 'lodash'

export const handleAction: EditorPlatformApp['handleAction'] = async (
  { type, payload },
  _editorSDK,
) => {
  switch (type) {
    case 'migrate': {
      console.log('migrate', payload)
      const api = await editorAppMetaData.getCoreApi()
      if (_.get(payload, 'badEmailsMigrations')) {
        await api.migrateBadEmails()
      } else if (_.get(payload, 'migrateTemplate')) {
        await api.createCollections()
      } else if (_.get(payload, 'getSubscribersMigration')){
        if (api.isADI()) {
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 3000))
        }
        await api.addForm.convertAllGetSubscribers(_.get(payload, 'getSubscribersMigration') as any)
      } else {
        api.interactionStarted('contact-form-migration')
        if (api.isADI()) {
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 3000))
        }
        await api.addForm.convertAllContactForms(payload as any)
        api.interactionEnded('contact-form-migration')
      }
    }
    default:
      break
  }
}
