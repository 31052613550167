import _ from 'lodash'
import {
  updateDesktopLayout,
  updateAlignment,
  updateAgreeTermsLayoutProps,
  updateMobileLayout,
  updatePhoneDesktopLayout,
  updatePhoneMobileLayout,
} from './get-subscribers-layout'
import { GetSubscribersFieldEnhancer, GSExtraData } from './get-subscribers-types'
import { isSkinWithFieldTitlesGS } from './get-subscribers-utils'
import { inputStyle, agreeStyle } from './get-subscribers-style'
import { wrapEnhancerWithRoles } from '../contact-form-service'
import { COMPLEX_CONTAINER_ROLES, COMPLEX_PHONE_ROLES, FIELDS } from '../../../../constants/roles'

export const textInputFieldLabelPlaceholderMapping = (
  extraData: GSExtraData,
  name: string,
  settings: GetSubscribersSettings,
) => {
  switch (name) {
    case 'email':
      return {
        label: extraData.desktopLayouts.email.label?.textData.label,
        placeholder: extraData.desktopLayouts.email.input.textData.placeholder,
      }
    case 'name':
      return {
        label: extraData.desktopLayouts.name.label?.textData.label,
        placeholder: extraData.desktopLayouts.name.input.textData.placeholder,
      }
    case 'phone':
      return {
        label: extraData.desktopLayouts.phoneNumber.label?.textData.label,
        placeholder: extraData.desktopLayouts.phoneNumber.input.textData.placeholder,
      }
    case 'phoneCode':
      return {
        label: isSkinWithFieldTitlesGS(settings)
          ? extraData.translator.t('complexPhone.dropdown.defaultTitle')
          : extraData.desktopLayouts.phoneCode.label?.textData.label,
        placeholder: extraData.desktopLayouts.phoneCode.input.textData.placeholder,
      }
    case 'agree':
      return {
        label: extraData.desktopLayouts.consent.text.textData.label,
      }
  }
}

export const updateConfig: GetSubscribersFieldEnhancer = ({ convertedField, skippedRoles }) => {
  const action = (field) => {
    field.config = { ...(field as any).connectionConfig }
    delete (field as any).connectionConfig
    return field
  }
  return wrapEnhancerWithRoles(convertedField, skippedRoles, action)
}

export const updateCrmLabel: GetSubscribersFieldEnhancer = ({
  fieldData,
  convertedField,
  extraData,
  settings,
  skippedRoles,
}) => {
  const action = (field) => {
    const { label } = textInputFieldLabelPlaceholderMapping(extraData, fieldData.name, settings)
    field.config.crmLabel = label.substring(0, 150).trim() || fieldData.name
    return field
  }
  return wrapEnhancerWithRoles(convertedField, skippedRoles, action)
}

const updateLabel: GetSubscribersFieldEnhancer = ({
  settings,
  fieldData,
  convertedField,
  extraData,
  skippedRoles,
}) => {
  const action = (field) => {
    if (isSkinWithFieldTitlesGS(settings)) {
      const role = field.role || _.get(field, 'connections.items[0].role')
      const isPhoneCode = role === COMPLEX_PHONE_ROLES.DROPDOWN
      const fieldName = isPhoneCode ? 'phoneCode' : fieldData.name
      if (!isPhoneCode) {
        field.data.placeholder = ''
        field.props.placeholder = ''
      }
      field.data.label = textInputFieldLabelPlaceholderMapping(extraData, fieldName, settings).label
    }
    return field
  }
  return wrapEnhancerWithRoles(convertedField, skippedRoles, action)
}

export const updatePlaceholder: GetSubscribersFieldEnhancer = ({
  settings,
  fieldData,
  convertedField,
  extraData,
  skippedRoles = [],
}) => {
  const action = (field) => {
    if (!isSkinWithFieldTitlesGS(settings)) {
      const placeholder = textInputFieldLabelPlaceholderMapping(extraData, fieldData.name, settings)
        .placeholder
      field.data.label = ''
      field.data.placeholder = placeholder
      field.props.placeholder = placeholder
      field.props.textPadding = settings.style.appStyle === 0 ? 0 : 8
    }
    return field
  }
  return wrapEnhancerWithRoles(convertedField, skippedRoles, action)
}

const updateAgreeTerms: GetSubscribersFieldEnhancer = ({
  settings,
  fieldData,
  convertedField,
  extraData,
}) => {
  if (fieldData.name !== 'agree') {
    return convertedField
  }

  convertedField.data.checked = false

  const label = extraData.desktopLayouts.consent.text.textData.label
  const linkText = extraData.desktopLayouts.consent.link?.textData?.label ?? ''
  const indexOfLink = label.lastIndexOf(linkText)
  convertedField.data.label = indexOfLink >= 0 ? label.substring(0, indexOfLink) : label

  if (settings.gdpr.isPrivacyPolicyLinkEnabled) {
    convertedField.data.link = {
      type: 'ExternalLink',
      target: '_blank',
      url:
        settings.gdpr.policyLinkUrl || `https://www.${settings.gdpr.policyLinkText || 'google'}.com`,
    }
    convertedField.data.linkLabel = linkText
  }

  return convertedField
}

const updateRequired: GetSubscribersFieldEnhancer = ({
  fieldData,
  convertedField,
  skippedRoles,
}) => {
  const action = (field) => {
    field.props.required = fieldData.required
    return field
  }

  return wrapEnhancerWithRoles(convertedField, skippedRoles, action)
}

const paddingEnhancer: GetSubscribersFieldEnhancer = ({ convertedField, skippedRoles }) => {
  const action = (field) => {
    return _.merge({}, field, { props: { textPadding: 5 } })
  }
  return wrapEnhancerWithRoles(convertedField, skippedRoles, action)
}

const updateCountryValue: GetSubscribersFieldEnhancer = ({
  convertedField,
  skippedRoles,
}): RawComponentStructure => {
  const action = (field: Partial<RawComponentStructure>) => {
    field.data.value = 'US +1'
    return field as RawComponentStructure
  }
  return wrapEnhancerWithRoles(convertedField, skippedRoles, action)
}

const fieldStyle: GetSubscribersFieldEnhancer = ({
  convertedField,
  settings,
  extraData,
  fieldData,
  skippedRoles,
}) => {
  const getStyle = fieldData.name === 'agree' ? agreeStyle : inputStyle
  const action = (field) => {
    return _.merge({}, field, {
      style: { style: getStyle(settings.style.appStyle, extraData) },
    })
  }
  return wrapEnhancerWithRoles(convertedField, skippedRoles, action)
}

const createPhoneEnhancers = () => {
  const bindIgnoredRoles = (fn, skippedRoles) => {
    return function (args) {
      return fn({ ...args, skippedRoles })
    }
  }
  return [
    bindIgnoredRoles(paddingEnhancer, [
      FIELDS.ROLE_FIELD_COMPLEX_PHONE_WIDGET,
      COMPLEX_CONTAINER_ROLES.PHONE,
    ]),
    bindIgnoredRoles(updatePlaceholder, [
      COMPLEX_PHONE_ROLES.DROPDOWN,
      COMPLEX_CONTAINER_ROLES.PHONE,
      FIELDS.ROLE_FIELD_COMPLEX_PHONE_WIDGET,
    ]),
    bindIgnoredRoles(updateRequired, [
      FIELDS.ROLE_FIELD_COMPLEX_PHONE_WIDGET,
      COMPLEX_CONTAINER_ROLES.PHONE,
    ]),
    bindIgnoredRoles(updateAlignment, [
      FIELDS.ROLE_FIELD_COMPLEX_PHONE_WIDGET,
      COMPLEX_CONTAINER_ROLES.PHONE,
    ]),
    bindIgnoredRoles(updateConfig, []),
    bindIgnoredRoles(updateCrmLabel, [
      COMPLEX_PHONE_ROLES.DROPDOWN,
      COMPLEX_PHONE_ROLES.TEXT,
      COMPLEX_CONTAINER_ROLES.PHONE,
    ]),
    bindIgnoredRoles(updateLabel, [
      FIELDS.ROLE_FIELD_COMPLEX_PHONE_WIDGET,
      COMPLEX_CONTAINER_ROLES.PHONE,
    ]),
    bindIgnoredRoles(fieldStyle, [
      FIELDS.ROLE_FIELD_COMPLEX_PHONE_WIDGET,
      COMPLEX_CONTAINER_ROLES.PHONE,
    ]),
    bindIgnoredRoles(updateCountryValue, [
      FIELDS.ROLE_FIELD_COMPLEX_PHONE_WIDGET,
      COMPLEX_PHONE_ROLES.TEXT,
      COMPLEX_CONTAINER_ROLES.PHONE,
    ]),
    updatePhoneDesktopLayout,
    updatePhoneMobileLayout,
  ]
}

const commonEnhancers: GetSubscribersFieldEnhancer[] = [
  paddingEnhancer,
  updateConfig,
  updateCrmLabel,
  updateDesktopLayout,
  updateMobileLayout,
  updateAlignment,
  fieldStyle,
]

const textFieldEnhancers: GetSubscribersFieldEnhancer[] = [
  ...commonEnhancers,
  updatePlaceholder,
  updateRequired,
  updateLabel,
]

const agreeFieldEnhancers: GetSubscribersFieldEnhancer[] = [
  ...commonEnhancers,
  updateAgreeTerms,
  updateAgreeTermsLayoutProps,
]

const phoneFieldEnhancer: GetSubscribersFieldEnhancer[] = createPhoneEnhancers()

export const fieldEnhancerByFieldName = (name: string) => {
  switch (name) {
    case 'name':
    case 'email':
      return textFieldEnhancers
    case 'agree':
      return agreeFieldEnhancers
    case 'phone':
      return phoneFieldEnhancer
    default:
      return []
  }
}
