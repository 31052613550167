import CoreApi from '../../core-api'
import { EMPTY_EMAIL_ID, INVALID_EMAIL_ID, isNotEmptyEmailId } from '../../../../utils/utils'
import _ from 'lodash'
import RemoteApi from '../../../../panels/commons/remote-api'
import Experiments from '@wix/wix-experiments'
import {
  EmailResponse,
  EmptyState,
  Form as DomainForm,
  FormEmailSettings,
  UserEmail,
} from '@wix/ambassador-wix-form-builder-web/http'
import { withBi } from '../../decorators'
import { handleError } from '../../../forms-editor-app/monitoring'
import { EVENTS } from '../../../../constants/bi'

export type GetEmailsResponse = {
  email: string
  emailId: string
  failedToSave?: boolean
}[]

export default class EmailsNotificationsApi {
  private biLogger: BILogger
  private experiments: Experiments
  private boundEditorSDK: BoundEditorSDK
  private coreApi: CoreApi
  private remoteApi: RemoteApi
  private ravenInstance

  constructor(boundEditorSDK, coreApi: CoreApi, remoteApi, { biLogger, experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.remoteApi = remoteApi
    this.experiments = experiments
  }
  public async getEmails(emailIds: string[]) {
    if (!emailIds.length) {
      return []
    }
    const validEmailIds = _.filter(emailIds, (emailId) => emailId !== INVALID_EMAIL_ID)
    const isThereNonEmptyEmail = _.some(validEmailIds, isNotEmptyEmailId)

    if (!isThereNonEmptyEmail) {
      return validEmailIds.map((emailId) => ({ emailId, email: '' }))
    }

    return this.remoteApi.getEmailsById(validEmailIds)
  }

  private async _emailsSettingsFromSchema(emailSettings: FormEmailSettings) {
    const siteUsersData = await this.remoteApi.getSiteUsersData()
    const emptyState = emailSettings?.emptyState

    const emailsId =
      emailSettings?.sendTo?.emails?.emailIds ?? (emptyState === EmptyState.EMAILS ? [] : undefined)
    const selectedSiteUserIds =
      emailSettings?.sendTo?.users?.userIds ?? (emptyState === EmptyState.USERS ? [] : undefined)
    const isOwner = emailSettings?.sendTo?.owner

    const owner = _.find(siteUsersData, (user) => user.isOwner)
    const emails = emailsId && (await this.getEmails(emailsId))

    return {
      emails,
      siteUsersData,
      selectedSiteUsersIds: isOwner ? [owner.userId] : selectedSiteUserIds,
      inboxOptOut: !!emailsId,
    }
  }

  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async setEmail(
    componentRef: ComponentRef,
    {
      emailIndex,
      newEmail,
      currentEmails,
    }: {
      emailIndex: number
      newEmail: string
      currentEmails: GetEmailsResponse
    },
    _biData = {},
  ): Promise<GetEmailsResponse> {
    try {
      if (_.get(currentEmails, `[${emailIndex}].email`) === newEmail) {
        return currentEmails
      }

      let newEmailId = null

      if (!_.isEmpty(newEmail)) {
        const { emailId } = await this.remoteApi.insertEmail(newEmail)
        newEmailId = emailId
      } else {
        if (emailIndex === 0) {
          newEmailId = EMPTY_EMAIL_ID
        }
      }

      const newValidEmails = _.filter(
        _.cloneDeep(currentEmails),
        (email) => email.emailId !== INVALID_EMAIL_ID,
      )
      newValidEmails[emailIndex] = { emailId: newEmailId, email: newEmail }
      const emailIds = newValidEmails.map((email) => email.emailId)
      await this.coreApi.setComponentConnection(componentRef, { emailIds }, false)

      return newValidEmails
    } catch (err) {
      handleError(err, {
        tags: { funcName: 'settings.notifications.setEmail' },
        extra: { message: 'Failed to setEmail' },
      })
      const newEmails = _.cloneDeep(currentEmails)
      newEmails[emailIndex] = { emailId: INVALID_EMAIL_ID, email: newEmail, failedToSave: true }
      return newEmails
    }
  }

  public async deleteInactiveEmails(
    componentRef: ComponentRef,
    emails: GetEmailsResponse,
    emailsLimit: number,
  ): Promise<GetEmailsResponse> {
    if (emails.length <= emailsLimit) {
      return emails
    }

    const filteredEmails = _.filter(
      emails,
      (email) =>
        email !== null &&
        _.get(email, 'emailId') !== EMPTY_EMAIL_ID &&
        _.get(email, 'emailId') !== '',
    )

    const updatedEmails = filteredEmails.slice(0, emailsLimit)
    const emailIds = updatedEmails.map((email) => email.emailId)
    await this.coreApi.setComponentConnection(componentRef, { emailIds }, false)

    return updatedEmails
  }

  private _isEmailNotificationOnBiz() {
    if (this.coreApi.isADI()) {
      return this.experiments.enabled('specs.crm.FormsBizNewEmailsSettingsAdi')
    }

    return this.experiments.enabled('specs.crm.FormsBizNewEmailsSettings')
  }

  public async getEmailsAndSiteUsers(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection,
    form?: DomainForm,
  ): Promise<{
    emails: EmailResponse[]
    siteUsersData: UserEmail[]
    selectedSiteUsersIds?: string[]
    inboxOptOut: boolean
  }> {
    const isEmailNotificationsOnBiz = this._isEmailNotificationOnBiz()

    const connection =
      componentConnection || (await this.coreApi.getComponentConnection(componentRef))
    const config = _.get(connection, 'config')

    if (isEmailNotificationsOnBiz) {
      const formSchema =
        isEmailNotificationsOnBiz &&
        (form || (await this.coreApi.settings.getForm(componentRef, componentConnection)))
      const emailSettingsSchema = formSchema?.formMetadata?.emailSettings
      if (emailSettingsSchema) {
        return this._emailsSettingsFromSchema(emailSettingsSchema)
      } else {
        //TODO: update schema
        //TODO: return emailsSettings
      }
    }

    const actualEmailsIds: string[] = config.emailIds || [config.emailId, config.secondEmailId]

    const [emails, siteUsersData] = await Promise.all([
      this.getEmails(actualEmailsIds),
      this.remoteApi.getSiteUsersData(),
    ])

    const owner = _.find(siteUsersData, (user) => user.isOwner)

    const [firstEmail, ...restOfEmails] = emails
    let actualFirstEmail = firstEmail

    if (_.get(firstEmail, 'emailId') !== EMPTY_EMAIL_ID && !_.get(firstEmail, 'email')) {
      actualFirstEmail = { emailId: null, email: _.get(owner, 'email', '') }
    }

    return {
      emails: [actualFirstEmail, ...restOfEmails.filter((email) => email.email)],
      siteUsersData,
      selectedSiteUsersIds: config.selectedSiteUsersIds,
      inboxOptOut: config.inboxOptOut,
    }
  }

  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public setSelectedSiteUsersIds(
    formComponentRef: ComponentRef,
    selectedSiteUsersIds: string[],
    _biData = {},
  ) {
    return this.coreApi.setComponentConnection(
      formComponentRef,
      { inboxOptOut: false, selectedSiteUsersIds },
      false,
    )
  }
  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.UPDATE_EMAIL_NOTIFICATIONS_OPTION })
  public updateInboxOptOutSelection(formComponentRef: ComponentRef, optOut: boolean, _biData = {}) {
    return this.coreApi.setComponentConnection(formComponentRef, { inboxOptOut: optOut })
  }
}
