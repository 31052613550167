import CoreApi from '../core-api'
import Experiments from '@wix/wix-experiments'
import _ from 'lodash'
import { fieldsStore } from '../preset/fields/fields-store'
import RemoteApi from '../../../panels/commons/remote-api'
import { Form as DomainForm } from '@wix/ambassador-wix-form-builder-web/http'

export default class PanelsDataApi {
  private biLogger
  private boundEditorSDK: BoundEditorSDK
  private coreApi: CoreApi
  private experiments: Experiments
  private remoteApi: RemoteApi

  constructor(
    boundEditorSDK: BoundEditorSDK,
    coreApi: CoreApi,
    remoteApi: RemoteApi,
    { experiments, biLogger },
  ) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.experiments = experiments
    this.remoteApi = remoteApi
  }

  public loadSettingsPanelMainTab() {
    return Promise.resolve({
      payload: {},
      ok: true,
    })
  }

  public loadBaseAdiPanelData() {
    return Promise.all([
      this.coreApi.getLocale(),
      this.coreApi.getMetaSiteId(),
      this.coreApi.getUserId(),
      this.coreApi.originEditorType(),
      this.coreApi.getEditorSessionId(),
      this.coreApi.getExperiments(),
    ]).then(([locale, msid, userId, editorType, esi, experiments]) => {
      return {
        locale,
        msid,
        userId,
        editorType,
        esi,
        experiments,
      }
    })
  }

  public loadAdiPanelData({ componentRef, componentConnection }) {
    const shouldFetchForm =
      this.experiments.enabled('specs.crm.FormsEditorEditFormNameRemote') ||
      this.experiments.enabled('specs.crm.FormsBizNewEmailsSettingsAdi')
    return Promise.all([
      this.coreApi.fields.getFieldsSortByXY(componentRef),
      this.coreApi.settings.getSubmitButtonLabel(componentRef),
      this.coreApi.getComponentConnection(componentRef),
      this.coreApi.settings.getMessage(componentRef),
      this.coreApi.settings.getSubmitOptionsData(componentRef, componentConnection),
      this.coreApi.premium.getPremiumRestrictions(),
      this.coreApi.settings.getEmailsAndSiteUsers(componentRef, componentConnection),
      this.coreApi.isCollectionExists(componentRef, componentConnection),
      this.coreApi.getLabels(),
      shouldFetchForm
        ? this.remoteApi.formServiceClient.getForm(componentRef.id)
        : Promise.resolve({} as DomainForm),
    ]).then(
      async ([
        fields,
        submitButtonLabel,
        {
          config: { successActionType, formName: configFormName, preset },
        },
        { text: successMessage },
        links,
        { restrictions, currentAscendPlan },
        emailsPayload,
        isCollectionExists,
        labels,
        form,
      ]) => {
        const titleTranslationKeys = _.reduce(
          fieldsStore.all(),
          (acc, fieldPreset) => ({
            ...acc,
            [fieldPreset.fieldType]: _.get(fieldPreset, 'metadata.ADI_titleTranslationKey'),
          }),
          {},
        )
        const formName = this.experiments.enabled('specs.crm.FormsEditorEditFormNameRemote')
          ? form.name
          : configFormName
        const formInfo = shouldFetchForm ? { formPublicId: form.id } : {}

        let updatedFields = fields
        if (this.experiments.enabled('specs.crm.FormsShowLabelToggleMessageADI')) {
          updatedFields = fields.map(field => ({
            ...field,
            label: field.showLabel ? field.label : ''
          }))
        }

        return {
          fields: updatedFields,
          submitButtonLabel,
          successActionType,
          successMessage,
          links,
          restrictions,
          formName,
          preset,
          email: _.get(emailsPayload, 'emails[0]') || null,
          isCollectionExists,
          currentAscendPlan,
          titleTranslationKeys,
          labels,
          formPublicId: form.id,
          formRevision: form.revision,
          existingFormNames: this.experiments.enabled('specs.crm.FormsEditorEditFormNameRemote')
            ? await this.remoteApi.formServiceClient.getOtherFormNames({
                publicId: form.id,
              })
            : [],
          ...formInfo,
        }
      },
    )
  }
}
